import React, { useState, useEffect, useRef } from 'react';

function Home() {
  const [isRunning, setIsRunning] = useState(false);
  const [imageSrc, setImageSrc] = useState('/1.png');
  const audioRef = useRef(null);

  useEffect(() => {
    let imageTimer;

    if (isRunning) {
      setImageSrc('/2.png');

      const switchImage = () => {
        setImageSrc('/2.png');
        setTimeout(() => {
          setImageSrc('/1.png');
        }, 4000);
      };


      audioRef.current.volume = 0.2;
      audioRef.current.play();

      switchImage();
      imageTimer = setInterval(switchImage, 6000);
    } else {
      setImageSrc('/1.png');

      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    return () => {
      clearInterval(imageTimer);
    };
  }, [isRunning]);

  const handleStartStop = () => {
    setIsRunning(!isRunning);
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>PikuTraining🔥</div>
      <img src={imageSrc} alt="Exercise" style={styles.image} />
      <div onClick={handleStartStop} style={styles.startText}>
        {isRunning ? 'Stop' : 'Start🔥'}
      </div>
      <audio ref={audioRef} src="/pikukin.mp3" loop /> 
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    fontSize: '24px',
  },
  image: {

  },
  startText: {
    marginTop: '20px',
    fontSize: '36px',
    cursor: 'pointer',
    animation: 'blink 3s infinite',
  },
};

export default Home;
